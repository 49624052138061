<template>
	<div>
		<!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel start here -->
		<panel title="Form Input Wakil NPWPD" class="panel panel-danger">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> FORM INPUT BENTUK BADAN</h6>
            </template> -->
            <div class="pl-4 py-2 border rounded shadow-sm">
				<b-button variant="primary" class="px-4" to="/admin/wakil/list" pill>
                    <i class="fa fa-arrow-circle-left pr-2"></i> 
                    Kembali
                </b-button>
			</div>
			<div class="py-3">
                <b-card class="shadow-sm">
                        <b-form @submit.prevent="update" @keydown="form.onKeydown($event)" autocomplete="off">
                            <!-- <input type="hidden" name="_token" :value="csrf"> -->
                            <b-alert v-if="message" variant="danger" show>Data yang anda input tidak valid.</b-alert>
                            <h3>WAKIL NPWPD</h3>
                            <b-table-simple striped>
                                <b-tbody>
                                   <b-tr>
                                        <b-td class="font-weight-bold" width="300px">NIK <span class="text-danger">*</span></b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col md="6">
                                                    <input type="text" v-model="form.nik" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('nik')}" placeholder="Masukkan NIK"/>
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('nik')" class="form-text text-danger">{{errors.nik[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                   <b-tr>
                                       <b-td class="font-weight-bold">Nama <span class="text-danger">*</span></b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col sm="10">
                                                    <input type="text" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('nama')}" v-model="form.nama" placeholder="Masukkan Nama" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('nama')" class="form-text text-danger">{{errors.nama[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                   <b-tr>
                                       <b-td class="font-weight-bold">Jabatan <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="10">
                                                    <input type="text" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('jabatan')}" v-model="form.jabatan" placeholder="Masukkan Jabatan" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('jabatan')" class="form-text text-danger">{{errors.jabatan[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                   <!-- <b-tr>
                                       <b-td class="font-weight-bold">Status <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="6">
                                                     <v-select 
                                                    label="nama"
                                                    :reduce="nama => nama.id"
                                                    :options="statusOptions" 
                                                    v-model="form.aktif"
                                                    placeholder="Pilih Status"
                                                    ></v-select>
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('aktif')" class="form-text text-danger">{{errors.aktif[0]}}</em>
                                        </b-td>
                                   </b-tr> -->
                                   <b-tr>
                                       <b-td class="font-weight-bold">No. Surat Ketetapan <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="10">
                                                     <input type="text" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('no_sk')}" v-model="form.no_sk" placeholder="Masukkan Nomor Surat Ketetapan" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('no_sk')" class="form-text text-danger">{{errors.no_sk[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                   <b-tr>
                                       <b-td class="font-weight-bold">Tgl. Surat Ketetapan <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="6">
                                                     <div class="input-group">
                                                        <date-picker id="dateTimeInput" v-model="form.tgl_sk" :config="options" placeholder="-Pilih Tanggal-"></date-picker>
                                                        <div class="input-group-append">
                                                            <label for="dateTimeInput" class="input-group-text">
                                                                <i class="fa fa-calendar"></i>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('tgl_sk')" class="form-text text-danger">{{errors.tgl_sk[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                    <b-tr>
                                       <b-td class="font-weight-bold">Status Surat Ketetapan <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="6">
                                                     <v-select 
                                                        label="nama"
                                                        :reduce="nama => nama.id"
                                                        :options="statusSKOptions" 
                                                        v-model="form.status_sk"
                                                        placeholder="Pilih Status SK"
                                                        class="bg-white"
                                                    ></v-select>
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('status_sk')" class="form-text text-danger">{{errors.status_sk[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                   <b-tr>
                                       <b-td class="font-weight-bold">No. Handphone <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="10">
                                                     <input type="text" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('nomp')}" v-model="form.nomp" placeholder="Masukkan Nomor Handphone" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('nomp')" class="form-text text-danger">{{errors.nomp[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                   <b-tr>
                                       <b-td class="font-weight-bold">No. Telepon <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="10">
                                                     <input type="text" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('notelepon')}" v-model="form.notelepon" placeholder="Masukkan Nomor Telepon" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('notelepon')" class="form-text text-danger">{{errors.notelepon[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                   <b-tr>
                                       <b-td class="font-weight-bold">Email <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="10">
                                                     <input type="text" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('email')}" v-model="form.email" placeholder="Masukkan Email" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('email')" class="form-text text-danger">{{errors.email[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                    <b-tr>
                                       <b-td class="font-weight-bold">Upload Dokumen SK <span class="text-danger">*</span></b-td>
                                        <b-td>
                                          <b-row>
                                                <b-col sm="10">
                                                       <b-form-file v-model="form.dok_sk" plain @change="handleFile"></b-form-file>
                                                </b-col>
                                            </b-row>
                                            <em v-if="errors.hasOwnProperty('dok_sk')" class="form-text text-danger">{{errors.dok_sk[0]}}</em>
                                        </b-td>
                                   </b-tr>
                                </b-tbody>
                            </b-table-simple>

                        <div class="py-2">
                            <h3>ALAMAT</h3>
                        </div>
                        <b-table-simple striped>
                            <b-tbody>
                                <b-tr>
                                    <b-td class="font-weight-bold" width="300px">Alamat</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col sm="10">
                                                    <input type="text" class="form-control" v-model="form.alamat" placeholder="Masukkan Alamat" />
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td class="font-weight-bold" width="300px">Provinsi</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col sm="6">
                                                <v-select 
                                                label="nama" 
                                                :options="provinsiOptions"
                                                :reduce="nama => nama.id"
                                                v-model="input.provinsi"
                                                @input="onProvinsi(input.provinsi)"
                                                placeholder="Pilih Provinsi">
                                                </v-select>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td class="font-weight-bold">Kota/Kabupaten</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col sm="6">
                                                <v-select 
                                                    label="nama" 
                                                    :options="kotaOptions"
                                                    :reduce="nama => nama.id"
                                                    v-model="input.kabkota"
                                                    @input="onKabKota(input.kabkota)"
                                                    placeholder="Pilih Kabupaten/Kota"
                                                    class="bg-white"
                                                ></v-select>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                </b-tr>
                                 <b-tr>
                                    <b-td class="font-weight-bold">Kecamatan</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col sm="6">
                                                <v-select 
                                                    label="nama" 
                                                    :options="kecamatanOptions"
                                                    :reduce="nama => nama.id"
                                                    v-model="input.kecamatan"
                                                    @input="onKecamatan(input.kecamatan)"
                                                    placeholder="Pilih Kecamatan"
                                                ></v-select>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td class="font-weight-bold">Kelurahan</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col sm="6">
                                                <v-select 
                                                    label="nama" 
                                                    :options="kelurahanOptions"
                                                    :reduce="nama => nama.id"
                                                    v-model="form.wilayah_kelurahan_id"
                                                    placeholder="Pilih Kelurahan"
                                                    class="bg-white"
                                                ></v-select>
                                            </b-col>
                                        </b-row>
                                        <em v-if="errors.hasOwnProperty('wilayah_kelurahan_id')" class="form-text text-danger">{{errors.wilayah_kelurahan_id[0]}}</em>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td class="font-weight-bold">Kodepos</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col sm="6">
                                                <input type="text" :class="{ 'form-control': true, 'is-invalid': errors.hasOwnProperty('kodepos')}" v-model="form.kodepos" placeholder="Masukkan Kodepos" />
                                            </b-col>
                                        </b-row>
                                        <em v-if="errors.hasOwnProperty('kodepos')" class="form-text text-danger">{{errors.kodepos[0]}}</em>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <hr>
                        <div class="float-right">
                            <span>
                                <b-button variant="primary" type="submit" squared class="px-4" pill><i class="fa fa-save"></i> Simpan</b-button>
                            </span>
                            <span class="px-2">
                                <b-button variant="secondary" squared class="px-4" @click="reset" pill><i class="fa fa-redo-alt"></i> Reset</b-button>
                            </span>
                        </div>
                    </b-form>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
	</div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Logo from '@/assets/images/logoSumut.png'
import HeadTitle from '@/components/header/HRetribusi.vue'

import axios from 'axios'

export default {
    components: {
        HeadTitle
    },
    data () {
        return {
            imageLogo: Logo,
            npwpdId: JSON.parse(localStorage.getItem('user')).npwpd_id,
            form: new Form({
                nik: '',
                npwpd_id: '',
                nama: '',
                nomp: '',
                notelepon: null,
                email: '',
                alamat: '',
                wilayah_kelurahan_id: '',
                kodepos: '',
                jabatan: '',
                no_sk: '',
                tgl_sk: '',
                dok_sk: null,
                status_sk: ''
            }),

            /* // variabel form rekening
            rekening: {
                kodeNama: '',
                kodeRekening: '0'
            }, */
            errors: [],
            message: null,

            // vue-select options
            npwpdOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],
            // statusOptions: [
            //     {
            //         id: 1,
            //         nama: 'Aktif'
            //     },
            //     {
            //         id: 0,
            //         nama: 'Non Aktif'
            //     }
            // ],
            statusSKOptions: [
                {
                    id: 1,
                    nama: 'Pimpinan'
                },
                {
                    id: 2,
                    nama: 'Penanggung Jawab'
                }
            ],

            // Variable wilayah
            input: {
                kota: null,
                kecamatan: null,
                provinsi: null
            },
            // csrf token
            // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            // datepicker config
            options: {
				format: 'YYYY-MM-DD',
				useCurrent: false,
			},
        }
    },
    mounted () {
        this.getDetailWakil(this.$route.params.id)
        this.getProvinsi()
        this.getNpwpd()
    },
    methods: {
        handleFile (event) {
            const file = event.target.files[0]
            // console.log(file)
            this.form.dok_sk = file
        },
        // update data
        update () {
            this.form.npwpd_id = this.npwpdId
            const formData = new FormData()
            formData.append("dok_sk", this.form.dok_sk)
            formData.append("nik", this.form.nik)
            formData.append("npwpd_id", this.form.npwpd_id)
            formData.append("nama", this.form.nama)
            formData.append("nomp", this.form.nomp)
            formData.append("notelepon", this.form.notelepon)
            formData.append("email", this.form.email)
            formData.append("alamat", this.form.alamat)
            formData.append("wilayah_kelurahan_id", this.form.wilayah_kelurahan_id)
            formData.append("kodepos", this.form.kodepos)
            formData.append("jabatan", this.form.jabatan)
            formData.append("no_sk", this.form.no_sk)
            formData.append("tgl_sk", this.form.tgl_sk)
            formData.append("status_sk", this.form.status_sk)
            formData.append("_method", 'PUT')
            axios.post('/api/wajib-pajak/npwpd-wakil/' + this.$route.params.id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.$swal({
                    icon: 'success',
                    title: 'Data berhasil ditambah',
                    type: 'success',
                    confirmButtonClass: 'btn btn-info',
                    showConfirmButton: true,
                    confirmButtonText: "OKE",
                    showCloseButton: true,
                    timer: 5000
                })
                this.form.reset()
                this.$router.push({ name: "ListWakilNpwpd"})
                /*  this.form.reset()
                this.kodeNama= ''
                this.message = false */
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data
                    this.message = error.response.data.message
                } else if (error.response.status == 401) {
                    localStorage.removeItem("EP_token")
                    this.$router.push({name: "Login"})
                }
                console.log(error)
            })
        },

        // fungsi untuk membuat kode rekening
        changeKodeRekening (code) {
            if (this.rekening.kodeNama == '') {
                this.rekening.kodeRekening = '0'
                this.form.kode = ''
            } else {
                this.form.kode = code
            }
        },

        //Wilayah
        // saat provinsi di input
        onProvinsi (id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = []
                this.input.kota = null
                this.kecamatanOptions = []
                this.input.kecamatan = null
                this.kelurahanOptions = []
                this.form.kelurahan_id = null
            } else {
                this.getKabupaten(id)
            }
        },
        // saat kab./kota di input
        onKabKota (id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = []
                this.input.kecamatan = null
                this.kelurahanOptions = []
                this.form.kelurahan_id = null
            } else {    
                this.getKecamatan(id)
            }
        },

        // saat kab./kota di input
        onKecamatan (id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = []
                this.input.kecamatan = null
                this.kelurahanOptions = []
                this.form.kelurahan_id = null
            } else {    
                this.getKelurahan(id)
            }
        },

        // Provinsi
        getProvinsi () {
            axios.get('/api/data-induk/wilayah-indonesia/provinsi/getoption').then(response => {
                this.provinsiOptions = response.data.data
                // console.log(this.provinsiOptions)
            }).catch(error => {
                console.log(error)
                if (error.response.error == 401) {
                    localStorage.removeItem("EP_token")
                    this.$router.push({name: "Login"})
                }
            })
        },
        // Kabupaten
        getKabupaten (id) {
            axios.get('/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-' + id).then(response => {
                // console.log(response.data.data)
                this.kotaOptions = response.data.data
            }).catch(error => {
                if (error.response.error == 401) {
                    localStorage.removeItem("EP_token")
                    this.$router.push({name: "Login"})
                }
            })
        },
        // Kecamatan
        getKecamatan (id) {
            axios.get('/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-' + id).then(response => {
                // console.log(response.data.data)
                this.kecamatanOptions = response.data.data
            }).catch(error => {
                if (error.response.error == 401) {
                    localStorage.removeItem("EP_token")
                    this.$router.push({name: "Login"})
                }
            })
        },

         // Kelurahan
        getKelurahan (id) {
            axios.get('/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-' + id).then(response => {
                // console.log(response.data.data)
                this.kelurahanOptions = response.data.data
            }).catch(error => {
                if (error.response.error == 401) {
                    localStorage.removeItem("EP_token")
                    this.$router.push({name: "Login"})
                }
            })
        },

        // npwpd options
        getNpwpd () {
            axios.get('/api/wajib-pajak/npwpd/getoption').then(response => {
                this.npwpdOptions = response.data.data
            }).catch(error => {
                console.log(error)
                if (error.response.error == 401) {
                    localStorage.removeItem("EP_token")
                    this.$router.push({name: "Login"})
                }
            })
        },

        // detail npwpd wakil
        getDetailWakil (id) {
            axios.get("/api/wajib-pajak/npwpd-wakil/" + id).then(response => {
                const items = response.data.data
                this.form.fill(items)
                this.input.provinsi = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                this.getKabupaten(this.input.provinsi)
                this.input.kabkota = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                this.getKecamatan(this.input.kabkota)
                this.input.kecamatan = items.wilayah_kelurahan.wilayah_kecamatan_id
                this.getKelurahan(this.input.kecamatan)
                //  console.log(response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({name: 'Login'})
                }
            })
        },
        
        // reset
        reset () {
            this.form.reset()
            this.input.provinsi = null
            this.input.kota = null
            this.input.kecamatan = null
            this.input.kelurahan = null
        }
    }
}
</script>